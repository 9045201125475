/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "@fontsource/open-sans";
import "@fontsource/source-sans-pro";
import "@fontsource/nova-round";
import "@fontsource/bebas-neue";
import "@fontsource/comfortaa";

import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import "./layout.css";

const Layout = ({ children, title, isHome, hideFooter, type, hideLayout }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {(title && title) || "4BAGR | Next Level Cornhole Leagues"}
        </title>
      </Helmet>
      {!hideLayout && (
        <Header
          isHome={isHome}
          siteTitle={(title && title) || `4BAGR | Cornhole Leagues`}
          type={type}
        />
      )}
      <main>{children}</main>
      {!hideFooter && !hideLayout ? <Footer /> : null}
    </>
  );
};

export default Layout;
