import React, { useEffect } from "react";
import { Link } from "gatsby";
import logo from "../images/4bagr-icon.png";
import logoScoreholio from "../images/4bagr-logo-scoreholio.png";
import appStore from "../images/appstore.png";
import googleplay from "../images/googleplay.png";

const Header = ({ isHome, type, hideLayout }) => {
  function resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset;
    const shrinkOn = 200;
    const headerEl = document.getElementById("top-header");

    // console.log("distanceY: ", distanceY);

    if (distanceY > shrinkOn) {
      headerEl.classList.add("smaller-header");
    } else {
      headerEl.classList.remove("smaller-header");
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", resizeHeaderOnScroll);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", resizeHeaderOnScroll);
    };
  });

  return (
    <header
      className={
        type === "howto" ? "howto-header" : !isHome ? "gradient-header" : ""
      }
      id="top-header"
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 20,
        }}
      >
        <Link to="/" className="logo-link">
          <img className="logo" src={logoScoreholio} alt="4Bagr app demo" />
        </Link>
        <div className="links-wrap">
          {isHome ? (
            <Link
              to="/how-to"
              activeClassName="nav-link-active"
              className="nav-link"
            >
              Docs
            </Link>
          ) : null}
          <div>
            <a
              href="https://apps.apple.com/us/app/4bagr/id1530693268"
              target="_blank"
            >
              <img
                className="appstore-icon"
                src={appStore}
                alt="download on app store"
              />
            </a>
          </div>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.auth0.fourbagr"
              target="_blank"
            >
              <img
                className="playstore-icon"
                src={googleplay}
                alt="get it on google play"
              />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
