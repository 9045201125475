import { Link } from "gatsby";
import React from "react";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";

const Footer = () => (
  <footer>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <div>
        <a href="https://www.facebook.com/4Bagr">
          <FaFacebookSquare style={{ fontSize: 40, color: "#fff" }} />
        </a>
      </div>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Link
        style={{
          color: "#fff",
          textDecoration: "none",
        }}
        to="/TOS/"
      >
        Terms of Service
      </Link>
      <span
        style={{
          margin: "0 7px",
          lineHeight: 0,
          paddingBottom: 3,
          color: "#fff",
        }}
      >
        |
      </span>
      <Link style={{ color: "#fff", textDecoration: "none" }} to="/privacy/">
        Privacy Policy
      </Link>
    </div>
    <span style={{ marginTop: 6, fontSize: ".7rem", color: "#fff" }}>
      © {new Date().getFullYear()} 4BAGR, LLC
    </span>
  </footer>
);

export default Footer;
